import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import LoadingSpinner from './components/LoadingSpinner';
import { Helmet } from 'react-helmet';

// Lazy load pages
const HomePage = lazy(() => import('./pages/HomePage'));
const ProductsPage = lazy(() => import('./pages/ProductsPage'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const ServicePage = lazy(() => import('./pages/ServicePage'));
const FAQPage = lazy(() => import('./pages/FAQPage'));

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
          <link rel="preload" as="image" href="/logo.webp" />
          <link rel="preload" as="style" href="/main.css" />
          <meta name="theme-color" content="#2563EB" />
          <link rel="manifest" href="/manifest.json" />
        </Helmet>

        <Navigation />
        
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/faq" element={<FAQPage />} />
          </Routes>
        </Suspense>

        <footer className="bg-gray-800 text-white py-6 mt-12">
          <div className="container mx-auto px-4 text-center">
            <p>© 2024 지니보드. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;